<template>
  <RegisterLayout>
    <Toast />
    <Dialog
      header=" "
      v-model:visible="showDialog"
      :modal="true"
      position="top"
      :style="{ maxWidth: '482px', marginTop: '70px' }"
    >
      <div class="flex mb-4 flex-column align-items-center">
        <div class="mt-2 mb-4">
          <img
            src="../../../../public/layout/images/priti/check.svg"
            alt="Checklist"
          />
        </div>
        <h2 class="tw-font-extrabold tw-text-3xl tw-mb-6">
          Pendaftaran berhasil!
        </h2>
        <div class="text-center">
          Mohon menunggu kabar dari admin kami melalui email untuk
          mengkonfirmasi pendaftaran anda
        </div>
      </div>

      <template #footer>
        <div class="flex mb-4 flex-column align-items-center">
          <Button
            label="Kembali ke halaman utama"
            class="tw-bg-blue-primary hover:tw-bg-blue-light"
            :style="{ width: '80%', height: '60px' }"
            @click="backToLandingPage"
          />
        </div>
      </template>
    </Dialog>
    <section
      class="tw-flex-1 tw-z-10 sm:tw-relative tw-bg-white sm:tw-rounded-t-none tw-rounded-t-lg tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-p-3 sm:tw-p-5 tw-overflow-y-auto"
    >
      <div class="md:tw-px-14">
        <Steps
          :model="items"
          readonly
          aria-label="Form Steps"
          class="tw-hidden sm:tw-block tw-my-12 tw-mx-14"
        />
      </div>
      <div class="md:tw-px-7 lg:tw-px-16">
        <FormKit
          type="form"
          id="registration"
          :actions="false"
          ref="form"
          :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
          @submit="register"
        >
          <router-view
            v-slot="{ Component }"
            :formData="formObject"
            @prev-page="prevPage($event)"
            @next-page="nextPage($event)"
            @complete="complete"
          >
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </FormKit>
      </div>
    </section>
  </RegisterLayout>
</template>
<script setup>
import RegisterLayout from "../../../layouts/RegisterLayout.vue";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useRegisterSalon } from "@/composables/salon";
//
const registerSalon = useRegisterSalon();
//
const toast = useToast();

const router = useRouter();

const showDialog = ref(false);

const form = ref();

const items = ref([
  {
    label: "Salon",
    to: "/register",
    icon: "pi pi-building",
  },
  {
    label: "Location",
    to: "/register/location",
    icon: "pi pi-map",
  },
  {
    label: "Admin",
    to: "/register/admin",
    icon: "pi pi-user",
  },
]);

onMounted(() => {
  if (router.currentRoute.value.path !== "/register") {
    router.push("/register");
  }
});

const formObject = ref({});

const nextPage = (event) => {
  formObject.value = { ...formObject.value, ...event.formData };
  router.push(items.value[event.pageIndex + 1].to);
};
const prevPage = (event) => {
  router.push(items.value[event.pageIndex - 1].to);
};

const buildForm = (formkitData) => {
  const form = new FormData();
  Object.entries(formkitData).forEach(([key, val]) => {
    if (key === "email") {
      form.append(key, val.toLowerCase());
    } else {
      form.append(key, val);
    }
  });

  Object.entries(formObject.value).forEach(([key, val]) => {
    if (key === "captcha_resp") {
      form.append("g-recaptcha-response", val.toLowerCase());
    } else if (key === "media") {
      val.forEach((data, idx) => {
        let name = "images";
        if (idx === 0) name = "image";
        form.append(name, data.file);
      });
    } else {
      form.append(key, val);
    }
  });

  return form;
};

const register = async (data) => {
  try {
    const formData = buildForm(data);
    const response = await registerSalon.salonService.registerSalon(formData);
    const message = response.data.message;

    if (response.data.code !== 200) {
      toast.add({
        severity: "error",
        summary: "Registrasi Gagal",
        detail: message,
        life: 3000,
      });
      return;
    }
    toast.add({
      severity: "success",
      summary: "Registrasi Berhasil",
      detail: "Salon berhasil terdaftar",
      life: 3000,
    });

    showDialog.value = true;
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Registrasi Gagal",
      detail: e.response.data.message,
      life: 3000,
    });
  }
};

const backToLandingPage = () => {
  window.location.href = "https://salonial.com";
};

const complete = (event) => {
  // retrieve the core node (several ways to do this):
  formObject.value = { ...formObject.value, ...event.formData };
  const node = form.value.node;
  // submit the form!
  node.submit();
};
</script>
<style>
:deep(.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus) {
  box-shadow: none !important;
}

:deep(.p-steps-current:focus) {
  box-shadow: none;
}
</style>
